import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h3>
    <p>{`Use an AutoComplete when you want to suggest related options that can be
selected as a user types into it. It makes searching and selecting from a large
collection of options easier.`}</p>
    <h3 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}</h4>
    <p>{`A default AutoComplete should always be paired with a clear label. It should
indicate what sort of input the field requires in a short an concise way.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="300" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D3%253A113&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h3 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h3>
    <p>{`When a user clicks into the text input field, they trigger the selection menu,
which is a list of possible options. The user can then selects a single option
from that list.`}</p>
    <p>{`When a user selects the text input field and starts typing, they trigger the
selection menu which contains a list of suggested options. The options are being
filterd based on every change made by the user, either by keyboard or mouse.`}</p>
    <p>{`A clear all "x" appears on user input and clears everything in the input field.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="550" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D9%253A0&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h3 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Include a clear and concise label. An AutoComplete without a label is
ambiguous and not accessible.`}</li>
      <li parentName="ul">{`Always sort the list of options in a way that makes sense to the user`}<ul parentName="li">
          <li parentName="ul">{`alphabetically`}</li>
          <li parentName="ul">{`numerically`}</li>
          <li parentName="ul">{`most selected options`}</li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h3>
    <p>{`Chameleon has a few components that give users the ability to choose from a list
of options.`}</p>
    <p><strong parentName="p">{`TextField`}</strong>{`: For an input field without suggested options, use a
`}<a parentName="p" {...{
        "href": "/components/textfield/code"
      }}>{`TextField`}</a>{` component.`}</p>
    <p><strong parentName="p">{`Select`}</strong>{`: For a single selection of a list of possible options, use a
`}<a parentName="p" {...{
        "href": "/components/select/code"
      }}>{`Select`}</a>{` component.`}</p>
    <p><strong parentName="p">{`Checkbox`}</strong>{`: For selecting options that require a button press to apply the
setting, use a `}<a parentName="p" {...{
        "href": "/components/checkbox/code"
      }}>{`Checkbox`}</a>{` component.`}</p>
    <p><strong parentName="p">{`Switch`}</strong>{`: For turning an option on or off instantly, use a
`}<a parentName="p" {...{
        "href": "/components/switch/code"
      }}>{`Switch`}</a>{` component.`}</p>
    <p><strong parentName="p">{`Radio`}</strong>{`: For selecting a single option from a set of options, use a
`}<a parentName="p" {...{
        "href": "/components/radio/code"
      }}>{`Radio`}</a>{` component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      